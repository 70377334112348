import { Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { UserResolver } from './core/resolvers/user.resolver';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'programs',
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadComponent: () => import('./core/layouts/base/base.layout').then(m => m.BaseLayout),
    resolve: {
      userData: UserResolver,
    },
    children: [
      {
        path: 'push',
        loadComponent: () => import('./features/push/push.component').then(m => m.PushComponent),
        loadChildren: () => import('./features/push/push-routes').then(m => m.pushRoutes),
      },
      {
        path: 'move-subscription',
        loadComponent: () =>
          import('./features/move-subscription/move-subscription.component').then(
            m => m.MoveSubscriptionComponent
          ),
        loadChildren: () =>
          import('./features/move-subscription/move-subscription-routes').then(
            m => m.moveSubscriptionRoutes
          ),
      },
      {
        path: 'programs',
        loadComponent: () =>
          import('./features/programs/programs.component').then(m => m.ProgramsComponent),
        loadChildren: () =>
          import('./features/programs/programs-routes').then(m => m.programsRoutes),
      },
      {
        path: 'lessons-groups',
        loadComponent: () =>
          import('./features/lesson-groups/lesson-groups.component').then(
            m => m.LessonGroupsComponent
          ),
        loadChildren: () =>
          import('./features/lesson-groups/lesson-groups.routes').then(m => m.lessonGroupsRoutes),
      },
      {
        path: 'program-groups',
        loadComponent: () =>
          import('./features/program-groups/program-groups-root.component').then(
            m => m.ProgramGroupsRootComponent
          ),
        loadChildren: () =>
          import('./features/program-groups/program-groups.routes').then(
            m => m.programGroupsRoutes
          ),
      },
      {
        path: 'users',
        loadComponent: () => import('./features/users/users.component').then(m => m.UsersComponent),
        loadChildren: () => import('./features/users/users-routes').then(m => m.usersRoutes),
      },
      {
        path: 'activity-points',
        loadComponent: () =>
          import('./features/activity-points/activity-points.root').then(
            m => m.ActivityPointsRootComponent
          ),
        loadChildren: () =>
          import('./features/activity-points/activity-points.routes').then(
            m => m.activityPointsRoutes
          ),
      },
      {
        path: 'packages',
        loadComponent: () =>
          import('./features/packages/packages.component').then(m => m.PackagesRootComponent),
        loadChildren: () =>
          import('./features/packages/packages-routes').then(m => m.packagesRoutes),
      },
      {
        path: 'lessons',
        loadComponent: () =>
          import('./features/lessons/lessons.component').then(m => m.LessonsRootComponent),
        loadChildren: () => import('./features/lessons/lessons-routes').then(m => m.lessonsRoutes),
      },
      {
        path: 'banners',
        loadComponent: () =>
          import('./features/banners/banners-root.component').then(m => m.BannersRootComponent),
        loadChildren: () => import('./features/banners/banners-routes').then(m => m.bannersRoutes),
      },
      {
        path: 'localization',
        loadComponent: () =>
          import('./features/localization/localization.component').then(
            m => m.LocalizationComponent
          ),
        loadChildren: () =>
          import('./features/localization/localization-routes').then(m => m.localizationRoutes),
      },
      {
        path: 'competitions',
        loadComponent: () =>
          import('./features/competitions/competitions.root').then(
            m => m.CompetitionsRootComponent
          ),
        loadChildren: () =>
          import('./features/competitions/competitions.routes').then(m => m.competitionsRoutes),
      },
    ],
  },
  {
    path: 'login',
    loadComponent: () => import('./features/login/login.component').then(m => m.LoginComponent),
    loadChildren: () => import('./features/login/login-routes').then(m => m.loginRoutes),
  },
  { path: '**', redirectTo: 'programs' },
];
